<template>
  <template v-if="photo">
    <img class="h-[18px] w-[18px] rounded-md bg-cover" :src="photo" :alt="`Foto do ${name}`" />
  </template>
  <template v-else-if="name">
    <Avatar :label="name.charAt(0)" />
  </template>
  <template v-else>
    <Avatar icon="uil uil-user" />
  </template>
</template>

<script setup>
const props = defineProps({
  photo: {
    type: String,
    required: false,
    default: undefined,
  },
  name: {
    type: String,
    required: false,
    default: undefined,
  },
});
</script>

<style lang="scss" scoped></style>
