import { VerticalCategoriesEntry } from "@enums/OrderEnum";
import axios from "axios";

import Network from "@/services/Fetcher/Network";
import { getHubspotFormId } from "@/utils/envHelper";
import Tracker from "@/utils/Tracker/Tracker";

export type ActivePromoResponse = {
  id: number;
  description: string;
  coupon: string;
  condition: string;
  active: true;
  date_start: string;
  date_end: string;
  created_at: string;
  updated_at: string;
}[];

type HomeFormData = {
  name: string;
  phone: string;
  email: string;
  cnpj: string;
  budget: string;
  city: string;
  state: string;
  verticals: VerticalCategoriesEntry[];
  verticals_suggest: boolean;
  contact_type: string;
  type?: string;
  company?: string;
  client?: string;
};

export const sendHSHomeForm = async (formData: HomeFormData): Promise<string> => {
  const portalId = "5803045";
  const formId = getHubspotFormId();
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

  const namePieces = formData.name.split(" ");
  const fName = namePieces.shift();
  const lName = namePieces.join(" ");
  const registerType = formTypeToHubMap[formData.type] || "N/A";
  let verticalsValue = formData.verticals?.map((v) => verticalsToHubMap[v.code] ?? "").join(";") ?? "nao informado";
  if (formData.verticals_suggest) {
    verticalsValue = "prefiro_que_me_sugira_ambientes";
  }

  const payload = {
    fields: [
      createFieldEntry("firstname", fName ?? ""),
      createFieldEntry("lastname", lName),
      createFieldEntry("phone", formData.phone),
      createFieldEntry("email", formData.email),
      createFieldEntry("cnpj___anunciante", formData.cnpj),
      createFieldEntry("0-2/name", formData.company || formData.name),
      createFieldEntry("city", formData.city),
      createFieldEntry("state", formData.state),
      createFieldEntry("investimento_valor_detalhado", formData.budget),
      createFieldEntry("verticais_de_interesse", verticalsValue),
      createFieldEntry("preferencia_de_contato", formData.contact_type),
      createFieldEntry("classificacao_de_contato", registerType),
    ],
    context: {
      pageUri: window.location.href,
      pageName: "Eletromidia Aqui",
    },
  };

  const response = await axios(url, {
    data: payload,
    method: Network.METHOD_POST,
    headers: {
      [Network.Header.ContentType]: Network.APPLICATION_JSON,
    },
  });

  Tracker.getInstance().track(Tracker.EVENT_FORM_SUBMIT, {
    form_id: formId,
    form_name: "Formulario Home Eletromidia Aqui",
    form_destination: "Hubspot",
    form_submit_text: "Obrigado pelo seu interesse em anunciar na Eletromidia.",
  });

  const origemUsuario = getOrigin();

  Tracker.getInstance().track(Tracker.EVENT_LEAD_GENERATE, {
    lead_source: origemUsuario,
    lead_id: await Tracker.hash(formData.email + Date.now()),
    investment: formData.budget,
    interest: verticalsValue,
  });

  return response.data.inlineMessage;
};

function getOrigin(): string {
  const referer = document.referrer;

  if (referer.includes("google.com")) {
    return "Google";
  } else if (referer.includes("facebook.com")) {
    return "Facebook";
  } else if (referer) {
    return referer;
  } else {
    return "";
  }
}

const createFieldEntry = (name: string, value: string) => ({
  objectTypeId: "0-1",
  name,
  value,
});

const verticalsToHubMap = {
  edificios_comerciais: "Edificios comerciais",
  edificios_residenciais: "Edificios residenciais",
  shoppings: "Shoppings",
  ruas: "Transportes sobre trilhos",
  transportes: "Aeroportos",
  aeroportos: "Ruas (mobiliário urbano)",
};

const formTypeToHubMap = {
  person: "Pessoa Física",
  company: "Empresa / Matriz",
  agency: "agencia / franquia",
};
