<template>
  <section class="hubspotform container relative mx-auto flex flex-col rounded bg-white p-8 md:p-[60px] 2xl:max-w-8xl">
    <div v-if="!isFormFinished" id="hbspt-form">
      <Steps :model="steps" :activeStep="stepWalker.currentIndex" />
      <form class="mt-[32px]">
        <div v-show="stepWalker.currentIndex === 0" id="panel-0">
          <FormItem
            v-model="formModel.name"
            type="text"
            name="name"
            label="Nome"
            iconLeft="uil-user"
            :validator="validateFullName"
          />

          <FormItem
            v-model="formModel.email"
            type="text"
            name="email"
            label="E-mail corporativo"
            iconLeft="uil-envelope"
            :validator="validateEmail"
          />

          <FormItem
            v-model="formModel.phone"
            type="mask"
            mask="['(##) #####-####', '(##) ####-####']"
            name="phone"
            label="Telefone"
            iconLeft="uil-mobile-android"
            :validator="validatePhone"
          />

          <div class="mt-2">
            <label class="custom-label" for="contact_type" :class="labelStyleBig"
              >Como prefere receber o contato?</label
            >
            <FormItem
              v-model="formModel.contact_type"
              type="radio"
              name="contact_type"
              :labelHide="true"
              :options="contactTypeOptions"
              :validator="validateNonEmptyValue"
            />
          </div>
        </div>
        <div v-show="stepWalker.currentIndex === 1" id="panel-1">
          <span :class="labelStyleBig">Quem e você?</span>
          <FormItem
            v-model="formModel.type"
            type="radio"
            name="type"
            :labelHide="true"
            :required="false"
            :options="typeOptions"
            @on-change="handleFormTypeChange"
          />

          <div v-if="formModel.type === 'company'">
            <FormItem
              v-model="formModel.company"
              type="text"
              name="company"
              label="Nome da empresa"
              :validator="validateNonEmptyValue"
            />
            <FormItem
              v-model="formModel.cnpj"
              type="mask"
              mask="##.###.###/####-##"
              name="cnpj"
              label="CNPJ"
              :validator="validateCNPJ"
            />
          </div>

          <div v-if="isTypeAgency">
            <FormItem
              v-model="formModel.company"
              type="text"
              name="company"
              label="Nome da agência"
              :validator="validateNonEmptyValue"
            />
            <FormItem
              v-model="formModel.cnpj"
              type="mask"
              mask="##.###.###/####-##"
              name="cnpj"
              label="CNPJ"
              :validator="validateCNPJ"
            />

            <FormItem
              v-model="formModel.client"
              type="text"
              name="client"
              :required="false"
              label="Nome do seu cliente"
            />
          </div>
        </div>

        <div v-show="stepWalker.currentIndex === 2" id="panel-2">
          <span :class="labelStyleBig">Onde você está?</span>
          <div class="direction-row flex">
            <div class="mr-2 grow">
              <FormItem
                v-model="formModel.state"
                type="select"
                name="state"
                placeholder="Estado"
                :labelHide="true"
                :options="stateOptions"
                :validator="validateNonEmptyValue"
              />
            </div>

            <div class="ml-2 grow">
              <FormItem
                v-model="formModel.city"
                type="text"
                name="city"
                placeholder="Cidade"
                :labelHide="true"
                :validator="validateNonEmptyValue"
              />
            </div>
          </div>

          <label class="custom-label" for="budget" :class="labelStyleBig">Quanto pretende investir?</label>
          <FormItem
            v-model="formModel.budget"
            type="select"
            name="budget"
            :labelHide="true"
            :options="budgetOptions"
            :validator="validateNonEmptyValue"
          />

          <div class="mt=2">
            <label class="custom-label" for="verticals" :class="labelStyleBig">Ambientes de interesse (opcional)</label>
            <FormItem
              v-model="formModel.verticals"
              type="vertical"
              name="verticals"
              gridSize="2 2 2"
              mode="compact"
              :labelHide="true"
              :required="false"
              :value="[]"
              :options="verticalOptions"
            />
          </div>

          <FormItem
            v-model="formModel.verticals_suggest"
            type="checkbox"
            name="verticals_suggest"
            :labelHide="true"
            :options="verticalSuggestOptions"
          />
        </div>

        <div class="mt-4">
          <Button
            class="w-full text-center"
            icon="uil uil-angle-right"
            icon-pos="right"
            :label="advanceButtonLabel"
            :loading="hbFormHook.loading"
            :disabled="!canAdvanceNextStep || hbFormHook.loading"
            @click.prevent="nextStep"
          />
        </div>
        <div class="mt-4">
          <p class="w-full text-center text-[10px] text-neutral-gray6 lg:text-base">
            Ao prosseguir com o envio, você concorda com nossos

            <br />
            <a
              class="text-neutral-gray6 underline"
              :href="ExternalLinks.documents['termos-e-condicoes']"
              target="_blank"
            >
              Termos e Condições</a
            >.
          </p>
        </div>
      </form>
    </div>
    <div v-if="isFormFinished" class="hubspotform-success" v-html="formEndMessage"></div>
  </section>
</template>

<script setup>
import FormItem from "@components/FormItem/FormItem.vue";
import { optionFactory, optionFactorySingle } from "@components/FormItem/optionFactory";
import { StatesNamesList } from "@enums/LocationEnum";
import { VerticalCategoriesAsList } from "@enums/OrderEnum";
import useFetcher from "@hooks/useFetcher";
import { computed, onMounted, ref, watch } from "vue";

import ExternalLinks from "@/content/ExternalLinks.json";
import { sendHSHomeForm } from "@/services/hubspotService";
import { isLocal } from "@/utils/envDetector";
import { getHubspotFormId } from "@/utils/envHelper";
import Tracker from "@/utils/Tracker/Tracker";
import { validateCNPJ, validateEmail, validateFullName, validateNonEmptyValue, validatePhone } from "@/utils/validator";

const props = defineProps({
  config: {
    type: Object,
    default: () => ({}),
  },
});

const labelStyleBig = "font-bold text-2xl text-black";

const typeOptions = [
  optionFactory("Pessoa Física", "person"),
  optionFactory("Empresa", "company"),
  optionFactory("Agência", "agency"),
];
const verticalOptions = VerticalCategoriesAsList;
const stateOptions = StatesNamesList;
const verticalSuggestOptions = [optionFactory("prefiro que me sugira ambientes", true)];
const contactTypeOptions = [optionFactory("Ligação", "telefone"), optionFactory("WhatsApp", "whatsapp")];
const budgetOptions = [
  optionFactory("até R$ 5.000", "5.000"),
  optionFactory("de R$ 5.000 a R$ 10.000", "até 10.000"),
  optionFactory("de R$ 10.000 a R$ 50.000", "de 10.000 a 50.000"),
  optionFactory("acima de R$ 50.000", "de 50.000 a 100.000"),
];

const hbFormHook = useFetcher(sendHSHomeForm);

// const formEndMessage = ref("<h1><span>Obrigado pelo seu interesse em anunciar na Eletromidia.</span><span>&nbsp;</span></h1><p>&nbsp;</p><p><span>Sua solicitação será analisada e entraremos em contato com você em até 8 horas, de segunda a sexta, em horário comercial.</span></p><p></p>",);
const formEndMessage = ref("");
const isFormFinished = ref(false);
const canAdvanceNextStep = ref(false);
const steps = ref([
  {
    key: "1",
    id: "step-1",
    validate: () =>
      checkValidations([
        validateFullName(formModel.value.name),
        validatePhone(formModel.value.phone),
        validateEmail(formModel.value.email),
        validateNonEmptyValue(formModel.value.contact_type),
      ]),
  },
  {
    key: "2",
    id: "step-2",
    validate: () => {
      return checkValidations([validateNonEmptyValue(formModel.value.company), validateCNPJ(formModel.value.cnpj)]);
    },
  },
  {
    key: "3",
    id: "step-3",
    validate: () =>
      checkValidations([
        validateNonEmptyValue(formModel.value.state),
        validateNonEmptyValue(formModel.value.city),
        validateNonEmptyValue(formModel.value.budget),
      ]),
  },
]);

const INITIAL_STEP = 0;
const stepWalker = ref({
  currentIndex: INITIAL_STEP,
  currentStep: steps.value[INITIAL_STEP],
  size: steps.value.length,
  lastIndex: steps.value.length - 1,
});

const formModel = ref({
  name: "",
  phone: "",
  email: "",
  cnpj: "",
  budget: "",
  city: "",
  state: "",
  verticals: [],
  verticals_suggest: false,
  contact_type: "",
  company: "",
  client: "",
});

const isTypeAgency = computed(() => formModel.value.type === "agency");
// const isTypeCompany = computed(()=>formModel.value.type === 'company')
// const isTypePerson = computed(()=>formModel.value.type === 'person')

const advanceButtonLabel = computed(() => {
  let label = "avançar";
  if (stepWalker.value.currentIndex == 1) {
    Tracker.getInstance().track(Tracker.EVENT_FORM_START, {
      form_id: getHubspotFormId(),
      form_name: "Formulario Home Eletromidia Aqui",
      form_destination: "Hubspot",
    });
  }

  if (stepWalker.value.currentIndex === stepWalker.value.lastIndex) {
    label = "enviar";
  }

  return label;
});

const prevStep = () => moveStep(stepWalker.value.currentIndex - 1);
const nextStep = () => {
  const next = stepWalker.value.currentIndex + 1;
  const isLast = next >= stepWalker.value.size;

  if (!isLast) {
    moveStep(next);
    return;
  }

  sendForm();
};

function moveStep(newStep) {
  if (newStep < 0 || newStep >= stepWalker.value.max) {
    return;
  }

  stepWalker.value = {
    ...stepWalker.value,
    currentIndex: newStep,
    currentStep: steps.value[newStep],
  };
  canAdvanceNextStep.value = false;
}

function checkValidations(validationsList) {
  return validationsList.every((validationResult) => !validationResult);
}

async function sendForm() {
  const verticals = formModel.value.verticals;
  const interest = verticals.map((item) => item.name).join(", ");

  await hbFormHook.fetch(formModel.value);

  if (!hbFormHook.hasError) {
    const message = hbFormHook.response || "";
    const messageNoStyle = message.replace(/style="[^"]*"/g, "");
    formEndMessage.value = messageNoStyle;
    isFormFinished.value = true;
  } else {
    alert("Houve um erro ao enviar a solicitação");
  }
}

const handleFormTypeChange = (type) => {
  if (type === "person") {
    nextStep();
  }
};

onMounted(async () => {
  if (isLocal()) {
    /* formModel.value = {
      type: "person",
      name: "Eletro Teste",
      phone: "11111111111",
      email: "teste@teste.com.br",
      cnpj: "02173668000166",
      budget: "5.000",
      city: "São Paulo",
      state: "SP",
      verticals: VerticalCategoriesAsList,
      verticals_suggest: false,
      contact_type: "telefone",
      company: "Eletro company",
      client: "......",
    }; */
  }
});

watch(
  () => formModel.value,
  (form) => {
    canAdvanceNextStep.value = stepWalker.value.currentStep.validate();
  },
  { deep: true },
);
</script>

<style lang="scss">
.hubspotform {
  width: 450px;
  max-width: 100%;
  box-shadow: 0 0 10px lightgrey;
}

.hubspotform-success {
  font-family: Rethink Sans !important;
  h1 {
    font-size: 32px !important;
    font-weight: bold !important;
    line-height: 44px !important;
  }

  p {
    font-size: 14px !important;
  }
}

.custom-label {
  font-size: 16px;
}

html .p-button {
  font-size: 14px;
  font-weight: bold;
}
</style>
