<template>
  <div v-auto-animate>
    <section class="banner-container flex-col md:flex-row">
      <div class="banner-section banner-section-col flex">
        <div
          id="banner-section-1"
          class="banner-section flex min-h-[234px] cursor-pointer bg-special-black-friday-black lg:min-h-[360px] lg:cursor-default lg:items-center"
          @click="handleClick"
        >
          <video
            v-if="content && content.media.type === 'video'"
            autoplay
            muted
            loop
            playsInline
            class="w-full"
            width="100%"
            height="100%"
            :poster="content.media.thumb"
            :class="[
              content.firstItemMobile === 'media' ? 'order-1' : 'order-2',
              content.firstItemDesktop === 'media' ? 'lg:order-1' : 'lg:order-2',
            ]"
            :src="isMobile ? content.media.path.mobile : content.media.path.desktop"
          ></video>
        </div>
      </div>

      <div id="banner-section-4_container" class="banner-section banner-section-col flex">
        <div
          id="banner-section-4_2"
          class="banner-section flex h-[390px] flex-[1_0_0] flex-col items-start justify-center gap-2 px-[24px] py-[32px] lg:p-[10px_85px_10px_80px]"
          @click="handleClick"
        >
          <div class="flex flex-col items-start gap-5 self-stretch">
            <div class="flex flex-col items-start gap-5 self-stretch">
              <h1>
                Quando você anuncia na<br class="hidden lg:block" />
                Eletromidia, todo mundo vê!
              </h1>
              <p>
                Conecte sua marca, produto ou serviço com milhares de<br class="hidden lg:block" />
                consumidores anunciando em ambientes de grande circulação.
              </p>
            </div>
            <Button class="!bg-white !px-[17.5px] !py-[10.5px]" @click.stop="handleClick">
              <span class="text-lg !font-bold !text-neutral-gray7">anuncie agora</span>
            </Button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { useIsMobile } from "@/hooks";
import { rotaResultados } from "@/routes";

const { isMobile } = useIsMobile();

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
});
const handleClick = (event) => {
  window.location.href = rotaResultados.url;
};
</script>

<style lang="scss" scoped>
.banner-container {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.banner-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  margin: 0 auto;
  min-height: 234px;

  @media (min-width: 1024px) {
    min-height: 360px;
  }
}

.banner-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#banner-section-4_container {
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
}

#banner-section-4_2 {
  background-color: #ff4f00;
  flex: 1;
  width: 100%;
  height: 100%;
}
h1 {
  color: var(--global-textColor, #1f262e);
  font-size: 35px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

p {
  color: var(--global-textColor, #1f262e);

  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 31.5px;
}
</style>
